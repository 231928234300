import { get } from '@/api/implementation/app';
import { getPaginated } from '@/api/implementation/app/wrapper.js';

function autocomplete(page, perPage, search, sortBy, descending, params) {
  return getPaginated(`product-action/autocomplete`, page, perPage, search, sortBy, descending, params);
}

function show(productActionId) {
  return get(`product-action/${productActionId}`);
}

export {
  autocomplete,
  show
}
