<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="productAction.fields">
        <v-row justify="center">
          <v-col cols="12" lg="6" md="8" sm="10">
            <v-subheader class="pa-0">{{ $tc('productAction.title', 1) }}</v-subheader>
            <v-card>
              <v-card-text>
                <ProductActionsAutocomplete v-model="values.productAction"
                                            field="productAction"
                                            @change="fetchProductAction"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-fade-transition>
            <v-col v-if="productAction" cols="12" lg="6" md="8" sm="10">
              <v-subheader class="pa-0">{{ $t('productAction.fields.settings') }}</v-subheader>
              <v-card>
                <v-card-text>
                  <DynamicInput v-for="setting in productAction.settings"
                                :key="setting.key"
                                v-model="values.settings[setting.key]"
                                :label="setting.key"
                                :type="setting.type"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-fade-transition>
        </v-row>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import { show } from '@/api/endpoints/productAction.js';
import ProductProductAction from '@/application/models/productProductAction.js';
import ProductActionsAutocomplete from '@/components/autocompletes/ProductActions.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import DynamicInput from '@/components/DynamicInput.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';

export default {
  name: 'ProductProductActionForm',
  extends: Form,
  components: {
    DynamicInput,
    ProductActionsAutocomplete,
    KFieldGroup,
  },
  data: () => ({
    values: new ProductProductAction(),
    productAction: null,
  }),
  created() {
    this.fetchProductAction();
  },
  methods: {
    async fetchProductAction() {
      if (!this.values.productAction) return;
      const response = await show(this.values.productAction.id);
      this.productAction = response.data.data;
      this.values.settings = {};
    },
  },
};
</script>
