<template>
  <KPaginatedAutocomplete :paginator="autocomplete" v-bind="$attrs" v-on="$listeners" ref="autocomplete"/>
</template>

<script>
import { autocomplete } from '@/api/endpoints/productAction.js';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';

export default {
  name: 'ProductActionsAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete(page, perPage, search, sortBy, descending, params) {
      return autocomplete(page, perPage, search, sortBy, descending, params);
    },
  },
};
</script>
